import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";

import teamStyle from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.jsx";

import team1 from "assets/img/team/Shiji Tha (Sales Manager).png";
import team2 from "assets/img/team/Waleed Ismail (Buisness Development Manager).png";
import team3 from "assets/img/team/Mohammed Sajarudeen (Sales Executive).png";
import team4 from "assets/img/team/Alhamthan Sabiudeen (Sales Representative).png";

class TeamSection extends React.Component {
  render() {
    const { classes } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRounded,
      classes.imgFluid
    );
    return (
      <div className={classes.section}>
        {/* <h2 className={classes.title}>Here is our team</h2> */}
        <h3 className={classes.title} style={{  marginTop: 0, color: "#293F99" }}>We are here to <br/> always help you</h3>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={6} md={3}>
              <Card plain>
                <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
                  <img src={team1} alt="..." className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Shiji Tha
                  <br />
                  <small className={classes.smallTitle}>Sales Manager</small>
                </h4>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <Card plain>
                <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
                  <img src={team2} alt="..." className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Waleed Ismail
                  <br />
                  <small className={classes.smallTitle}>Buisness Development Manager</small>
                </h4>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <Card plain>
                <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
                  <img src={team3} alt="..." className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Mohammed Sajarudeen
                  <br />
                  <small className={classes.smallTitle}>Sales Executive</small>
                </h4>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <Card plain>
                <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
                  <img src={team4} alt="..." className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Alhamthan Sabiudeen
                  <br />
                  <small className={classes.smallTitle}>Sales Representative</small>
                </h4>
              </Card>
            </GridItem>
          </GridContainer>
        </div>        
      </div>
    );
  }
}

export default withStyles(teamStyle)(TeamSection);
