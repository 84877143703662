import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

import { CssBaseline, Typography } from "@material-ui/core";
import TeamSection from "./TeamSection";
import NewsLetterSection from "../LandingPage/Sections/NewsLetterSection";
import FooterSection from "../LandingPage/Sections/FooterSection";

const dashboardRoutes = [];

class AboutPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="AL MIDIAFF INTERNATIONAL SUPPLIES"
          // brand="AL MIDIAFF INTERNATIONAL Co. LLC"
          // brand="AL MIDIAFF"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <CssBaseline />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.section} style={{ padding: "0 15%" }}>
            <div style={{ height: 160 }}></div>

            <div className={classes.container}>
              <GridContainer aria-label="breadcrumb">

                <a href="/">Home /</a>
                &nbsp;
                <a href="#">About</a>

              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={9} md={8} style={{}}>
                  <h1 className={classes.title} style={{ color: "#6C648B" }}>About Us</h1>
                </GridItem>
              </GridContainer>
            </div>
            <div style={{ height: 60 }}></div>
            <div style={{ background: "#fff", maxWidth: "100%" }}>
              <div className={classes.section} style={{}}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3} style={{  }}>
                    <h3 className={classes.title} style={{  marginTop: 0, color: "#293F99" }}>Overview of Our <br/> Company</h3>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={9} style={{}}>
                    <br />
                    <Typography align="justify">
                      Al Midiaff International Co. LLC (AMICO) was established in the year 1993 in Abu Dhabi UAE with an objective of providing clients with wholesale supplies of industrial materials, which includes spares of engineering equipment, electrical products, para pharmaceutical compositions etc. . Over the years, the company served a broad spectrum of reputed clients, by meeting these requirements and providing competent and reliable services.
                    </Typography>
                    <br />
                    <Typography align="justify">
                      AL Midiaff's constant commitment and result-oriented approach have enabled the firm to emerge as one of the most favored and acknowledged companies in the middle east. Today, the company has an immersive experience and knowledge of local markets and an extensive network internationally to cater for clients such as ADNOC group, Abu Dhabi Distribution Company (ADDC) and Abu Dhabi Water and Electricity Authority (ADWEA) to name a few.
                    </Typography>
                    <br />
                    <Typography align="justify">
                      Our Clients benefit from our Products and service quality, which experience in meeting and exceeding the markets expectation.  Our principal is that we have received outstanding levels of support at the regional level, ensuring access to an extensive market network in both the public and the private sector.
                  </Typography>
                  </GridItem>
                </GridContainer>
              </div>
              <div style={{ height: 80 }}></div>
              <div className={classes.section} style={{}}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3} style={{  }}>
                    <h3 className={classes.title} style={{  marginTop: 0, color: "#293F99" }}>What makes us <br/> Special?</h3>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={9} style={{}}>
                    <br />
                    <Typography align="justify">
                    We are focused with equality to all our partner's in each task we are associated with. We constantly endeavor to improve over our industry and build up a firm and vigorous comprehension of our customers prerequisites. We are enthusiastic about accomplishing results that surpass desires - our own and our partners.
                    </Typography>
                    <br />
                    <Typography align="justify">
                    We work gently for positive outcomes with vitality and keep moving. We search new chances and unique industry standard arrangements.
                    </Typography>
                    <br />
                    <Typography align="justify">
                    Our tenacious interest to accomplish greatness in our industry as an internationally regarded business that conveys Reliability - Efficiency and Quality to our customers drives us in our every day tasks. 
                  </Typography>
                  </GridItem>
                </GridContainer>
              </div>
              <div className={classes.section} style={{ }}>
                <TeamSection/>
              </div>
            </div>
          </div>
          <div style={{ background: "#6C648B", width: "100%" }}>
            <div className={classes.container} >
              <NewsLetterSection />
            </div>
          </div>

          <div style={{background:"#B6A19E", minHeight:"2em"}}></div>
          <FooterSection />

          
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(landingPageStyle, productStyle)(AboutPage);
